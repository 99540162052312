import React from 'react';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import SearchObject from './SearchObject.type';
import SearchListItem from './SearchListItem';
import Category from './Category.type';
import { Breakpoint } from '@avensia/scope';
import * as style from 'Shared/Style';

interface Props {
  list: SearchObject[];
  categories: Category[];
}

const SearchList: React.SFC<Props> = props => {
  const list = props.list.map((searchItem, index) => {
    return (
      <SearchListItemS key={index}>
        <SearchListItem item={searchItem} categories={props.categories} />
      </SearchListItemS>
    );
  });

  return <SearchListUL>{list}</SearchListUL>;
};

const SearchListUL = styled.ul({
  marginBottom: '0',
  paddingLeft: '0',
  listStyle: 'none',
});

const SearchListItemS = styled.li({
  marginBottom: '36px',
  paddingLeft: '0',
  paddingRight: '0',
  overflow: 'hidden',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    width: '70%',
    paddingLeft: '20px',
    paddingRight: '20px',
  }),
});

export default SearchList;
