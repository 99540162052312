/**
 * @ComponentFor SearchPageResultViewModel
 */
import React from 'react';
import SearchPageResultViewModelType from './SearchPageResultViewModel.type';
import { epiPropertyValue, currentUrl, Breakpoint } from '@avensia/scope';
import Page from 'Shared/Page';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import { styled } from '@glitz/react';
import { postJson } from '@avensia/scope';
import { Main, Appearance } from 'Shared/PageLayout';
import SearchResult from './SearchResult.type';
import SearchObject from './SearchObject.type';
import SearchList from './SearchList';
import Category from './Category.type';
import TagButtons from './TagButtons';
import Viewport from 'Shared/Viewport';
import Spinner from 'Shared/Icon/Spinner';

type StateType = {
  unfilteredSearchObjects: SearchObject[];
  categories: Category[];
  totalHits: number;
  currentSearchObjects: SearchObject[];
  selectedFilters: string[];
  isExpanded: boolean;
  isSelected: boolean;
  isLoading: boolean;
};

type PropType = {} & SearchPageResultViewModelType;

class SearchPage extends React.Component<PropType, StateType> {
  ref: React.RefObject<HTMLDivElement>;
  constructor(props: PropType) {
    super(props);
    this.ref = React.createRef<HTMLDivElement>();
    this.state = {
      unfilteredSearchObjects: [],
      categories: [],
      totalHits: null,
      currentSearchObjects: [],
      selectedFilters: [],
      isExpanded: false,
      isSelected: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    postJson('/search' + currentUrl().search + '&offset=1').then((result: any) => {
      const objs = JSON.parse(result);
      const SearchResults = objs as SearchResult;
      this.setOurStates(SearchResults);
      this.setState({ isLoading: false });
    });
  }

  setOurStates = (result: SearchResult) => {
    this.setState({
      unfilteredSearchObjects: result.unfilteredSearchObjects,
      categories: result.categories,
      totalHits: result.totalHits,
      currentSearchObjects: result.unfilteredSearchObjects,
    });
  };

  filterUnfilteredSearchObjects = () => {
    const filteredItems =
      this.state.selectedFilters.length === 0
        ? this.state.unfilteredSearchObjects
        : this.state.unfilteredSearchObjects.filter(x => x.category.some(r => this.state.selectedFilters.includes(r)));

    this.setState({
      currentSearchObjects: filteredItems,
    });
  };

  addFilterToSelected = (key: string) => {
    if (!this.state.categories.some(x => x.id === key)) {
      return;
    }

    if (this.state.selectedFilters.some(x => x === key)) {
      const index = this.state.selectedFilters.indexOf(key);
      const filters = this.state.selectedFilters;
      filters.splice(index, 1);
      this.setState(
        {
          selectedFilters: filters,
        },
        this.filterUnfilteredSearchObjects,
      );
      return;
    } else {
      this.setState(
        {
          selectedFilters: [key].concat(this.state.selectedFilters),
        },
        this.filterUnfilteredSearchObjects,
      );
    }
    this.filterUnfilteredSearchObjects();
  };

  toggleSerachFilters = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  toggleIsSelected = () => {
    this.setState({
      isSelected: !this.state.isSelected,
    });
  };

  render() {
    const searchedFor = currentUrl().searchParams.get('query');
    const { isLoading } = this.state;

    const content = (
      <>
        {isLoading ? (
          <HitsFoundText>
            <Spinner />
          </HitsFoundText>
        ) : (
          <HitsFoundText>{this.state.totalHits || 0} hits found</HitsFoundText>
        )}

        <TagButtons
          categories={this.state.categories}
          addFilter={this.addFilterToSelected}
          selectedFilters={this.state.selectedFilters}
        />
      </>
    );

    return (
      <>
        <BkgPattern />
        <Page>
          <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
            <HeadingText>
              {epiPropertyValue(this.props.content.headerText)} {searchedFor}
            </HeadingText>
            <Viewport>
              {(isCompact: boolean) => (
                <>
                  {!this.state.isExpanded ? (
                    <RectangleMore innerRef={this.ref}>
                      {content}
                      {isCompact && <ShowHide onClick={this.toggleSerachFilters}>Show more</ShowHide>}
                    </RectangleMore>
                  ) : (
                    <RectangleLess innerRef={this.ref}>
                      {content}
                      {isCompact && <ShowHide onClick={this.toggleSerachFilters}>Show less</ShowHide>}
                    </RectangleLess>
                  )}
                </>
              )}
            </Viewport>
            <SearchList list={this.state.currentSearchObjects} categories={this.state.categories} />
          </MainWrapper>
        </Page>
      </>
    );
  }
}

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '29px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    margin: {
      top: '99px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const RectangleMore = styled.div({
  position: 'relative',
  paddingTop: '13px',
  paddingBottom: '26px',
  marginBottom: '40px',
  width: '100%',
  backgroundColor: '#F1F2F2',
  overflow: 'hidden',
  maxHeight: '246px',
});

const RectangleLess = styled.div({
  position: 'relative',
  paddingTop: '13px',
  paddingBottom: '26px',
  marginBottom: '40px',
  width: '100%',
  backgroundColor: '#F1F2F2',
});

const HeadingText = styled.h1({
  marginBottom: '0px',
  paddingTop: '3%',
  paddingBottom: '3%',
  fontSize: '40px',
});

const ShowHide = styled.p({
  position: 'absolute',
  top: '10px',
  right: '10px',
});

const HitsFoundText = styled.p({
  paddingLeft: '20px',
  paddingBottom: '13px',
  marginBottom: 0,
  fontWeight: 500,
  fontSize: '18px',
});

export default SearchPage;
