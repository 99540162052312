import React from 'react';
import { styled } from '@glitz/react';
import Category from './Category.type';
import { media } from '@glitz/core';
import { Breakpoint } from '@avensia/scope';
import * as style from 'Shared/Style';

interface Props {
  categories: Category[];
  addFilter: Function;
  selectedFilters: string[];
}

const TagButtons: React.SFC<Props> = props => {
  const tags = props.categories.map((tag, i) => {
    return (
      <TagWrapper key={i}>
        {tag.count ? (
          <Tag
            onClick={() => props.addFilter(tag.id)}
            style={
              props.selectedFilters.indexOf(tag.id) !== -1 ? { background: style.primaryDark, color: style.WHITE } : {}
            }
          >
            <TagText>
              {tag.name} ({tag.count})
            </TagText>
          </Tag>
        ) : null}
      </TagWrapper>
    );
  });

  return <>{tags}</>;
};

const Tag = styled.button({
  display: 'block',
  height: '36px',
  width: 'auto',
  marginLeft: '18px',
  marginBottom: '18px',
  background: '#fdfdfd',
  fontSize: '16px',
  borderRadius: '3px',
  padding: {
    left: '10px',
    right: '10px',
  },
  border: {
    xy: {
      color: '#888',
      width: 1,
      style: 'solid',
    },
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    marginBottom: 0,
    display: 'inline-block',
  }),
});

const TagText = styled.span({});
const TagWrapper = styled.span({});

export default TagButtons;
