import React from 'react';
import SearchObject from './SearchObject.type';
import { styled } from '@glitz/react';
import Category from './Category.type';
import * as style from 'Shared/Style';
import Link from 'Shared/Link';

interface Props {
  item: SearchObject;
  categories: Category[];
}

const SearchListItem: React.SFC<Props> = props => {
  const tags = props.item.category.map((obj, index) => {
    const category = props.categories.find(item => {
      return item.id === obj;
    });
    return category && <SearchTag key={index}>{category.name}</SearchTag>;
  });
  const urlParts = props.item.fullUrl.split('/');
  let tagUrl = '/';
  const urlTags = urlParts.slice(1, -2).map((part, index) => {
    tagUrl += part + '/';
    part = part.replace('-', ' ');
    part = ucfirst(part);
    return (
      <>
        {part ? (
          <SearchBreadcrumb key={index} url={tagUrl}>
            {part}
            <SearchBreadcrumbArrowStart />
            <SearchBreadcrumbArrowEnd />
          </SearchBreadcrumb>
        ) : null}
      </>
    );
  });

  return (
    <>
      <SearchItemHeader>
        <SearchItemHeading>
          <SearchItemLink to={props.item.url}>{props.item.title}</SearchItemLink>
        </SearchItemHeading>
        <TagsContainer>
          {urlTags} {tags}
        </TagsContainer>
      </SearchItemHeader>
      <SearchPreamble>{props.item.displayText}</SearchPreamble>
    </>
  );
};

const ucfirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const SearchItemHeader = styled.span({
  marginBottom: '10px',
});

const SearchItemHeading = styled.h2({
  display: 'inline-block',
  marginRight: '12px',
  marginBottom: '10px',
  fontSize: '18px',
  fontWeight: 500,
  color: '#522d7d',
});

const SearchItemLink = styled(Link, {
  color: '#522d7d',
});

const TagsContainer = styled.span({ whiteSpace: 'nowrap' });

interface SearchTagProps {
  children?: React.ReactNode;
}

const SearchTag: React.SFC<SearchTagProps> = ({ children }) => (
  <styled.Span
    css={{
      display: 'inline-block',
      marginLeft: '8px',
      padding: {
        x: '10px',
      },
      fontSize: '14px',
      lineHeight: '24px',
      height: '24px',
      color: style.BLACK,
      background: '#e4e4e4',
      borderRadius: '2px',
    }}
  >
    {children}
  </styled.Span>
);

interface SearchBreadcrumbProps {
  url: string;
  children?: React.ReactNode;
}

const SearchBreadcrumb: React.SFC<SearchBreadcrumbProps> = ({ url, children }) => (
  <Link
    to={url}
    css={{
      display: 'inline-block',
      marginLeft: '8px',
      padding: {
        x: '10px',
      },
      fontSize: '14px',
      lineHeight: '24px',
      height: '24px',
      background: style.primaryMedium,
      color: style.WHITE,
      position: 'relative',
      borderRadius: 0,
      ':hover': {
        color: style.WHITE,
      },
    }}
  >
    {children}
  </Link>
);

const SearchBreadcrumbArrowStart = () => (
  <styled.Svg
    width="7px"
    height="24px"
    viewBox="0 0 7 24"
    css={{
      fill: style.primaryMedium,
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: '-6px',
    }}
  >
    <path style={{ fillOpacity: 1, stroke: 'none' }} d="M 0,0 1,0 L 7,12 1,24 0,24 V 24 Z" />
  </styled.Svg>
);

const SearchBreadcrumbArrowEnd = () => (
  <styled.Svg
    width="8px"
    height="24px"
    viewBox="0 0 8 24"
    css={{
      fill: style.primaryMedium,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '-7px',
    }}
  >
    <path style={{ fillOpacity: 1, stroke: 'none' }} d="M 0,0 H 8 V 24 H 0 L 6,12 0,0 Z" />
  </styled.Svg>
);

const SearchPreamble = styled.p({
  marginBottom: 0,
  maxHeight: '52px',
  wordBreak: 'break-word',
});

export default SearchListItem;
